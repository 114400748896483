<template>
  <div class="browse-algorithms-sidebar">
    <div class="left">
      <icon-button icon="feather-home" class="active" @click="$modal.alert('Not implemented')" />
      <icon-button icon="feather-file" @click="$modal.alert('Not implemented')" />
      <icon-button icon="feather-star" @click="$modal.alert('Not implemented')" />
      <icon-button icon="feather-settings" @click="$modal.alert('Not implemented')" />
    </div>

    <div class="right">
      <ul>
        <!-- Featured -->
        <li>
          <span class="top-title">Featured</span>
          <featured-algorithms-list />
        </li>

        <!-- Algorithms -->
        <li class="mt-4">
          <span class="top-title">Algorithms</span>
          <ul>
            <li>
              <login-protected-button @click="myAlgorithms">
                <span class="menu-icon">👨</span> My algorithms
              </login-protected-button>
            </li>
            <li>
              <login-protected-button @click="findAlgorithm">
                <span class="menu-icon">🔎</span> Find algorithm
              </login-protected-button>
            </li>
            <li>
              <new-algorithm-link>
                <span class="menu-icon">➕</span> New algorithm
              </new-algorithm-link>
            </li>
          </ul>
        </li>

        <!-- Graphs -->
        <li class="mt-4">
          <span class="top-title">Graphs</span>
          <ul>
            <li>
              <login-protected-button @click="myGraphs">
                <span class="menu-icon">👨</span> My graphs
              </login-protected-button>
            </li>
            <li>
              <login-protected-button @click="findGraph">
                <span class="menu-icon">🔎</span> Find graph
              </login-protected-button>
            </li>
            <li>
              <login-protected-button @click="newGraph">
                <span class="menu-icon">➕</span> New graph
              </login-protected-button>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import NewAlgorithmLink from '../../components/links/NewAlgorithmLink.vue';
import LoginProtectedButton from '../../components/buttons/LoginProtectedButton.vue';
import FeaturedAlgorithmsList from '../../components/lists/FeaturedAlgorithmsList.vue';
import IconButton from '../../components/buttons/IconButton.vue';

export default {
  name: 'browse-algorithms-sidebar',
  components: {
    NewAlgorithmLink,
    LoginProtectedButton,
    FeaturedAlgorithmsList,
    IconButton,
  },
  methods: {
    myAlgorithms() {
      this.$modal.alert('Not implemented');
    },
    findAlgorithm() {
      this.$modal.alert('Not implemented');
    },
    myGraphs() {
      this.$modal.alert('Not implemented');
    },
    findGraph() {
      this.$modal.alert('Not implemented');
    },
    newGraph() {
      this.$modal.alert('Not implemented');
    },
  },
};
</script>

<style lang="scss">
$browse-algorithms-sidebar-width: 300px;
$left-icon-width: 50px;

.browse-algorithms-sidebar {
  display: grid;
  grid-template-columns: 50px $browse-algorithms-sidebar-width - $left-icon-width;

  height: 100%;
  width: $browse-algorithms-sidebar-width;

  background-color: #212529;

  .left {
    background-color: #1c1f23;

    button {
      display: flex;
      align-items: center;
      justify-content: center;

      width: $left-icon-width;
      height: $left-icon-width;

      border-radius: 0;

      color: #888;

      transition: all 250ms ease-in-out;

      &:hover,
      &.active {
        color: #ddd;
      }

      &.active {
        border-left: 4px solid darkcyan;
      }
    }
  }

  .right {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    ul {
      list-style: none;

      width: 100%;

      padding: 0;
      margin: 0;
      margin-top: 10px;
    }

    li {
      padding: 0;
      margin: 0;
    }

    .top-title {
      display: block;

      padding-left: 15px;
      margin-bottom: -5px;

      text-transform: uppercase;
      font-weight: 700;
      font-size: 0.8em;
    }

    button,
    a {
      display: block;

      width: 100%;

      padding: 4px 30px;

      color: #ddd;

      font-size: 14px;
      text-transform: capitalize;
      text-decoration: none;
      text-align: left;

      &:focus {
        box-shadow: none;
      }

      &:hover,
      &.router-link-active {
        color: #ddd;

        background-color: #1c1f23;
      }
    }

    .menu-icon {
      display: inline-block;
      font-size: 0.8em;
      margin-right: 2px;
      transform: translateY(-1px);
    }
  }
}
</style>

<template>
  <algorithm-editor />
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex';
import AlgorithmEditor from '../../components/AlgorithmEditor.vue';

export default {
  name: 'page-algorithm',
  metaInfo() {
    return {
      title: this.loading ? 'Loading...' : this.algorithm.name,
    };
  },
  components: {
    AlgorithmEditor,
  },
  computed: {
    ...mapGetters({
      algorithm: 'algorithm/getAlgorithm',
      loading: 'algorithm/isLoading',
    }),
  },
  methods: {
    ...mapActions({
      visualizeAlgorithm: 'visualizer/visualizeAlgorithm',
      fetchAlgorithm: 'algorithm/fetchAlgorithm',
    }),
    ...mapMutations({
      stopPlaying: 'visualizer/stopPlaying',
      setAlgorithm: 'code-editor/setAlgorithm',
    }),
    async selectedAlgorithm() {
      this.stopPlaying();

      await this.fetchAlgorithm(this.$route.params);

      // todo: maybe do this in store?
      this.setAlgorithm(this.algorithm); // code editor
      this.visualizeAlgorithm(this.algorithm); // visualizer
    },
  },
  async created() {
    await this.selectedAlgorithm();
  },
  watch: {
    '$route.params': 'selectedAlgorithm',
  },
};
</script>

import globalComponents from './global-components';
import modalPlugin from './plugins/modal';
import VueMeta from 'vue-meta';

export default {
  install(Vue) {
    Vue.use(globalComponents);

    Vue.use(modalPlugin);
    Vue.use(VueMeta);
  },
};

/** @typedef {import('@codemirror/view').EditorView} EditorView */

const FONT_SIZE_LOCAL_STORAGE_KEY = 'syga.code-editor.fontSize';
const DEFAULT_FONT_SIZE = 14; // px
const MIN_FONT_SIZE = 10; // px
const MAX_FONT_SIZE = 20; // px

const state = {
  /**
   * The EditorView of the code editor
   *
   * @type {EditorView}
   */
  view: null,

  /**
   * The current font size in pixels
   *
   * @type {number}
   */
  fontSize: Number(window.localStorage.getItem(FONT_SIZE_LOCAL_STORAGE_KEY)) || DEFAULT_FONT_SIZE,
};

const getters = {
  /**
   * Returns the editor view
   *
   * @param {state} state
   */
  getEditorView: ({ view }) => view,

  /**
   * Returns the state of the editor
   *
   * @param {state} state
   */
  getEditorState: ({ view }) => view.state,

  /**
   * Returns the code (text content) of the current EditorView object
   *
   * @param {state} state
   *
   * @returns {string}
   */
  getCode: (state) => getters.getEditorState(state).doc.toString(),

  /**
   * Returns the current font size of the editor in pixels
   *
   * @param {state} state
   *
   * @returns {number}
   */
  getFontSize: ({ fontSize }) => fontSize,
};

const actions = {};

const mutations = {
  /**
   * Sets the current EditorView object
   *
   * @param {state} state
   * @param {EditorView} payload
   */
  setView: (state, payload) => (state.view = payload),

  /**
   * Sets the code (text content) of the current EditorView object using the
   * specified algorithm.
   *
   * @param {state} state
   * @param {{ code: string }} payload
   */
  setAlgorithm: (state, { code }) => mutations.setCode(state, code),

  /**
   * Sets the code (text content) of the current EditorView object
   *
   * @param {state} state
   * @param {string} payload
   */
  setCode: (state, payload) => {
    const from = 0;
    const to = getters.getCode(state).length;

    state.view.dispatch({ changes: [{ from, to, insert: payload }] });
  },

  /**
   * Sets the font size.
   *
   * Integrity conditions are:
   *   - font size cannot be lesser than MIN_FONT_SIZE
   *   - font size cannot be greater than MAX_FONT_SIZE
   *   - font size must be an integer
   *
   * @param {state} state
   * @param {number} payload
   */
  setFontSize: (state, payload) => {
    if (isNaN(Number(payload))) {
      return; // Do not modify font size if invalid input
    }

    const fontSize = Math.min(MAX_FONT_SIZE, Math.max(MIN_FONT_SIZE, Math.round(payload)));

    state.fontSize = fontSize;
    window.localStorage.setItem(FONT_SIZE_LOCAL_STORAGE_KEY, fontSize.toString());
  },

  /**
   * Increases the font size of the editor by one pixel
   *
   * @param {state} state
   */
  increaseFontSize: (state) => mutations.setFontSize(state, getters.getFontSize(state) + 1),

  /**
   * Decreases the font size of the editor by one pixel
   *
   * @param {state} state
   */
  decreaseFontSize: (state) => mutations.setFontSize(state, getters.getFontSize(state) - 1),
};

const modules = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules,
};

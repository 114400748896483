<template>
  <router-link class="new-algorithm-link" to="/algorithm/new">
    <slot>
      <span>{{ title }}</span>
    </slot>
  </router-link>
</template>

<script>
export default {
  name: 'new-algorithm-link',
  props: {
    title: {
      type: String,
      default: 'New algorithm',
    },
  },
};
</script>

import Vue from 'vue';
import Vuex from 'vuex';

import algorithm from './algorithm/algorithm';
import visualizer from './visualizer/visualizer';
import modal from './modal/modal';
import codeEditor from './code-editor/code-editor';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    algorithm,
    visualizer,
    modal,
    'code-editor': codeEditor, // todo: change to codeEditor
  },
});

export default store;

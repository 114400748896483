<template>
  <component :is="type" @click.prevent="onClick">
    <slot></slot>
  </component>
</template>

<script>
import { LOGIN_MODAL_NAME } from '../../constants/constants';
import session from '../../api/session';

export default {
  name: 'login-protected-button',
  props: {
    type: {
      type: String,
      default: 'a',
      validator: (val) => val === 'a' || val === 'button',
    },
  },
  methods: {
    onClick() {
      session.loggedIn ? this.$emit('click') : this.$modal.open(LOGIN_MODAL_NAME);
    },
  },
};
</script>

<template>
  <g>
    <circle v-if="actualShape === 'circle'" :cx="x" :cy="y" :r="size" :fill="actualColor" />
    <rect
      v-if="actualShape === 'square'"
      :x="x - size"
      :y="y - size"
      :width="size * 2"
      :height="size * 2"
      :fill="actualColor"
    />
    <text font-size="10" fill="#000" text-anchor="middle" :x="x" :y="y + size * 0.35">
      {{ label }}
    </text>
    <!-- todo: triangle -->
    <!-- todo: diamond -->
    <!-- todo: hexagon -->
    <!-- todo: octagon -->
    <!-- todo: star -->
    <!-- todo: hidden -->
  </g>
</template>

<script>
const DEFAULT_COLOR = '#555';
const DEFAULT_SHAPE = 'circle';

export default {
  name: 'visualizer-graph-node',
  props: {
    x: {
      type: Number,
      required: true,
    },
    y: {
      type: Number,
      required: true,
    },
    color: {
      type: String,
      default: DEFAULT_COLOR,
    },
    shape: {
      type: String,
      default: DEFAULT_SHAPE,
    },
    size: {
      type: Number,
      required: true,
    },
    label: {
      type: [Number, String],
      required: true,
    },
  },
  computed: {
    actualColor() {
      return this.color || DEFAULT_COLOR;
    },
    actualShape() {
      return (this.shape || DEFAULT_SHAPE).toLowerCase();
    },
  },
};
</script>

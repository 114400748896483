<template>
  <icon-button
    :disabled="loading"
    icon="feather-package"
    :icon-size="iconSize"
    :text="textContent"
    @click="build"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'build-button',
  props: {
    iconSize: {
      type: Number,
      default: undefined,
    },
  },
  computed: {
    ...mapGetters({
      loading: 'visualizer/getLoading',
      code: 'code-editor/getCode',
    }),
    textContent() {
      return this.loading ? 'Building' : 'Build';
    },
  },
  methods: {
    ...mapActions({
      visualizeAlgorithm: 'visualizer/visualizeAlgorithm',
    }),
    async build() {
      await this.visualizeAlgorithm({ code: this.code });
    },
  },
};
</script>

<template>
  <div :style="computedStyle" class="split-wrapper" ref="wrapper">
    <slot></slot>
  </div>
</template>

<script>
import Split from 'split.js';

export default {
  name: 'split',
  props: {
    direction: {
      type: String,
      default: 'horizontal',
      validate: (value) => value === 'horizontal' || value === 'vertical',
    },
    gutterSize: {
      type: Number,
      default: 5,
    },
    initialSizes: {
      type: [Array],
      default: null,
    },
    splitId: {
      type: [String, Number],
      default: null,
    },
    saveState: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      split: null,
      sizes: null,
    };
  },
  computed: {
    nSplits() {
      if (!(this.$refs.wrapper instanceof HTMLElement)) {
        return 0;
      }

      return this.$refs.wrapper.children.length;
    },
    computedStyle() {
      return {
        'flex-direction': this.direction === 'horizontal' ? 'row' : 'column',
      };
    },
  },
  watch: {
    sizes() {
      if (this.saveState) {
        const key = this.lsKey('sizes');
        const value = JSON.stringify(this.sizes);

        window.localStorage.setItem(key, value);
      }
    },
  },
  methods: {
    lsKey(prop) {
      return `syga.split.${this.splitId}.${prop}`;
    },
    onDragStart(sizes) {
      this.sizes = sizes;
      this.$emit('dragstart', sizes);
    },
    onDrag(sizes) {
      this.sizes = sizes;
      this.$emit('drag', sizes);
    },
    onDragEnd(sizes) {
      this.sizes = sizes;
      this.$emit('dragend', sizes);
    },
  },
  mounted() {
    // 1. Retrieve state from local storage if enabled and valid
    if (this.saveState) {
      try {
        const sizes = JSON.parse(window.localStorage.getItem(this.lsKey('sizes')));

        if (Array.isArray(sizes) && sizes.length === this.nSplits) {
          this.sizes = sizes;
        }
      } catch {} // Ignore errors
    }

    // 2. Use initial sizes if they are valid and the sizes were not retrieved
    //    from the local storage
    if (this.sizes == null && this.initialSizes && this.initialSizes.length === this.nSplits) {
      this.sizes = [...this.initialSizes];
    }

    // 3. If options 1 and 2 failed, used equally spaced splits
    this.sizes = this.sizes || Array.from({ length: this.nSplits }, () => 100 / this.nSplits);

    this.split = Split(this.$refs.wrapper.children, {
      sizes: this.sizes,
      gutterSize: this.gutterSize,
      direction: this.direction,
      onDragStart: this.onDragStart.bind(this),
      onDrag: this.onDrag.bind(this),
      onDragEnd: this.onDragEnd.bind(this),
    });
  },
};
</script>

<style lang="scss">
.split-wrapper {
  display: flex;

  height: 100%;
  width: 100%;
}

.gutter {
  background-color: #444;
  background-repeat: no-repeat;
  background-position: 50%;
}

.gutter.gutter-vertical {
  cursor: row-resize;
}

.gutter.gutter-horizontal {
  cursor: col-resize;
}
</style>

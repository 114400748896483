<template>
  <div ref="wrapper" class="visualizer-console">
    <template v-for="(frame, index) in frames">
      <template v-if="frame.console_logs != ''">
        <div :key="`console-log-${index}`" :class="`console-log ${computeLogClass(index)}`">
          <pre>{{ frame.console_logs }}</pre>
          <span class="console-meta">{{ computeMetaText(frame) }}</span>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
// Todo: be careful about the performance of this component

import { mapGetters } from 'vuex';

export default {
  name: 'visualizer-console',
  computed: {
    ...mapGetters({
      frames: 'visualizer/getFrames',
      activeFrame: 'visualizer/getActiveFrame',
    }),
  },
  methods: {
    computeLogClass(index) {
      if (index > this.activeFrame) {
        return 'after';
      }

      if (index == this.activeFrame) {
        return 'active';
      }

      return 'before'; // index < this.activeFrame
    },
    computeMetaText({ lineno }) {
      if (!Array.isArray(lineno) || lineno.length === 0) {
        return '';
      }

      if (lineno.length === 1) {
        return `Line (${lineno[0]})`;
      }

      return `Lines (${lineno.join(',')})`;
    },
  },
  watch: {
    activeFrame() {
      this.$nextTick(() => this.$refs.wrapper.scrollTo(0, 999999));
    },
  },
};
</script>

<style lang="scss">
.visualizer-console {
  overflow-y: auto;

  background-color: #282c34;

  .console-log {
    position: relative;

    padding: 10px;
    margin: 0;

    &.before {
      opacity: 0.5;
    }

    &.active {
      background-color: rgba(0, 0, 0, 0.1);
    }

    &.after {
      display: none;
    }

    .console-meta {
      position: absolute;
      right: 10px;
      top: 10px;
    }
  }

  pre {
    margin: 0;
    padding: 0;

    color: inherit;
    background-color: transparent;
  }
}
</style>

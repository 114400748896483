<template>
  <algorithm-editor />
</template>

<script>
import AlgorithmEditor from '../../components/AlgorithmEditor.vue';
import session from '../../api/session';
import { mapActions, mapMutations } from 'vuex';

export default {
  name: 'page-home',
  components: {
    AlgorithmEditor,
  },
  methods: {
    ...mapActions({
      visualizeAlgorithm: 'visualizer/visualizeAlgorithm',
    }),
    ...mapMutations({
      startPlaying: 'visualizer/startPlaying',
      setCode: 'code-editor/setCode',
    }),
    async getWelcomeCode() {
      // todo: move this to the server.
      return (
        `
text = 'hello, ${session.username || 'stranger'}!'

G = engine.DiGraph([(i + 1, i + 2) for i in range(len(text) - 1)])

G.color_edges_by(lambda u, v, G: v if 'label' in G.nodes[v] else None)
G.color_nodes_by(lambda v, G: v if 'label' in G.nodes[v] else None)
G.label_nodes_by(lambda v, G: G.nodes[v]['label'] if 'label' in G.nodes[v] else ' ')

for i, symbol in enumerate(text):
  G.nodes[(i + 1)]['label'] = symbol

print(text)
`.trim() + '\n'
      );
    },
  },
  async mounted() {
    const code = await this.getWelcomeCode();

    this.setCode(code);
    await this.visualizeAlgorithm({ code, enableCache: true });

    this.startPlaying();
  },
};
</script>

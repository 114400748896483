import connector from '../../api/connector';

const state = {
  /**
   * The currently displayed algorithm.
   *
   * @type {Algorithm}
   */
  algorithm: null,

  /**
   * Whether the algorithm is being fetched.
   *
   * @type {boolean}
   */
  loading: false,
};

const getters = {
  /**
   * Returns the currently displayed algorithm.
   *
   * @param {state}
   */
  getAlgorithm: ({ algorithm }) => algorithm,

  /**
   * Returns whether the algorithm is being fetched.
   *
   * @param {state}
   */
  isLoading: ({ loading }) => loading,
};

const actions = {
  /**
   * Fetches the algorithm with the specified namespace, slug and version and
   * sets it as the currently displayed algorithm.
   *
   * @param {{ commit: (mutation: string, payload: *) => void }}
   * @param {{ namespace: string, slug: string, version: string }}
   */
  fetchAlgorithm: async ({ commit }, { namespace, slug, version }) => {
    try {
      commit('startLoading');

      const { algorithm } = await connector.get(
        `/algorithm/detail/${namespace}/${slug}/${version}`
      );

      commit('setAlgorithm', algorithm);
    } finally {
      commit('stopLoading');
    }
  },
};

const mutations = {
  /**
   * Sets the currently displayed algorithm.
   *
   * @param {state} state
   * @param {Algorithm} payload
   */
  setAlgorithm: (state, algorithm) => (state.algorithm = algorithm),

  /**
   * Starts the loading.
   *
   * @param {state} state
   */
  startLoading: (state) => (state.loading = true),

  /**
   * Stops the loading.
   *
   * @param {state} state
   */
  stopLoading: (state) => (state.loading = false),
};

const modules = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules,
};

import player from './player';
import connector from '../../api/connector';

/**
 * @typedef Frame
 * @prop {Array<number>} lineno
 * @prop {string} console_logs
 * @prop {Array} components
 */

const INITIAL_VISUALIZED_CODE = () => null;
const INITIAL_RES = () => null;
const INITIAL_ERR = () => null;
const INITIAL_ELAPSED = () => null;
const INITIAL_ACTIVE_FRAME = () => -1;
const INITIAL_FRAMES = () => [];
const INITIAL_LOADING = () => false;
const INITIAL_HIGHLIGHT_ENABLED = () => true;
const INITIAL_CACHE_ENABLED = () => true; // todo: use window.localStorage
const INITIAL_RESPONSE_TIMESTAMP = () => 0;

const state = {
  /**
   * The code currently being visualized.
   *
   * @type {string}
   */
  visualizedCode: INITIAL_VISUALIZED_CODE(),

  /**
   * The result of the visualization
   *
   * @type {'success'|'mixed'|'error'}
   */
  res: INITIAL_RES(),

  /**
   * The error message
   *
   * @type {string}
   */
  err: INITIAL_ERR(),

  /**
   * The elapsed time in seconds
   *
   * @type {number}
   */
  elapsed: INITIAL_ELAPSED(),

  /**
   * The index of the active frame or -1 if there are no frames
   *
   * @type {number}
   */
  activeFrame: INITIAL_ACTIVE_FRAME(),

  /**
   * The frames generated by the algorithm visualization engine
   *
   * @type {Array<Frame>}
   */
  frames: INITIAL_FRAMES(),

  /**
   * The timestamp of the last response from the visualization engine
   *
   * @type {number}
   */
  responseTimestamp: INITIAL_RESPONSE_TIMESTAMP(),

  /**
   * Whether the app is waiting for a response from the visualization REST API
   *
   * @type {boolean}
   */
  loading: INITIAL_LOADING(),

  /**
   * Whether line highlighting is enabled
   *
   * @type {Boolean}
   */
  highlightEnabled: INITIAL_HIGHLIGHT_ENABLED(),

  /**
   * Whether cache is enabled.
   *
   * @type {boolean}
   */
  cacheEnabled: INITIAL_CACHE_ENABLED(),
};

const getters = {
  /**
   * Getter for the `res` property
   *
   * @param {state} state
   */
  getRes: ({ res }) => res,

  /**
   * Getter for the `err` property
   *
   * @param {state} state
   */
  getErr: ({ err }) => err,

  /**
   * Getter for the `elapsed` property
   *
   * @param {state} state
   */
  getElapsed: ({ elapsed }) => elapsed,

  /**
   * Getter for the `activeFrame` property
   *
   * @param {state} state
   */
  getActiveFrame: ({ activeFrame }) => activeFrame,

  /**
   * Returns `true` if the active frame is the first one
   *
   * @param {state} state
   */
  isFirstFrame: ({ activeFrame }) => activeFrame === 1,

  /**
   * Returns `true` if the active frame is the last one
   *
   * @param {state} state
   */
  isLastFrame: ({ activeFrame, frames }) => activeFrame == frames.length - 1,

  /**
   * Returns the active line
   *
   * @param {state} state
   */
  getActiveLines: ({ activeFrame, frames }) =>
    activeFrame in frames ? frames[activeFrame].lineno : [],

  /**
   * Getter for the `frames` property
   *
   * @param {state} state
   */
  getFrames: ({ frames }) => frames,

  /**
   * Returns the number of frames
   *
   * @param {state} state
   */
  getNFrames: ({ frames }) => frames.length,

  /**
   * Returns the timestamp of the last response from the visualization engine
   *
   * @param {state} state
   */
  getResponseTimestamp: ({ responseTimestamp }) => responseTimestamp,

  /**
   * Returns `true` if the app is waiting for a visualization REST API response
   *
   * @param {state} state
   */
  getLoading: ({ loading }) => loading,

  /**
   * Returns `true` if line highlighting is enabled; `false` otherwise
   *
   * @param {state} state
   */
  getHighlightEnabled: ({ highlightEnabled }) => highlightEnabled,

  /**
   * Returns `true` if cache is enabled; `false` otherwise.
   *
   * @param {state} state
   */
  getCacheEnabled: ({ cacheEnabled }) => cacheEnabled,
};

const actions = {
  /**
   * Sends a request to the visualization REST API to generate frames from the
   * specified code
   *
   * @throws {Exception} When there is an XHR error
   *
   * @param {{commit: (mutation: String, payload: *) => void, state: state}}
   * @param {{ code: string, enableCache: boolean }}
   */
  visualizeAlgorithm: async ({ commit, state }, { code, enableCache = false }) => {
    try {
      commit('reset');
      commit('startLoading');
      commit('setVisualizedCode', code);

      const data = await connector.post('/algorithm/execute', {
        code,
        enableCache: enableCache || state.cacheEnabled,
      });

      // If the code had been changed before the response is received, do not
      // use the response for visualization.
      if (code == state.visualizedCode) {
        commit('setRes', data.visualization.res);
        commit('setErr', data.visualization.err);
        commit('setElapsed', data.visualization.elapsed);
        commit('setFrames', data.visualization.frames || []);
        commit('setResponseTimestamp', Date.now());
      }
    } finally {
      commit('stopLoading');
    }
  },
};

const mutations = {
  /**
   * Setter for the `visualizedCode` property.
   *
   * @param {state} state
   * @param {string} payload
   */
  setVisualizedCode: (state, payload) => (state.visualizedCode = payload),

  /**
   * Setter for the `res` property
   *
   * @param {state} state
   * @param {string} payload
   */
  setRes: (state, payload) => (state.res = payload),

  /**
   * Setter for the `err` property
   *
   * @param {state} state
   * @param {string} payload
   */
  setErr: (state, payload) => (state.err = payload),

  /**
   * Setter for the `elapsed` property
   *
   * @param {state} state
   * @param {number} payload
   */
  setElapsed: (state, payload) => (state.elapsed = payload),

  /**
   * Setter for the `activeFrame` property
   *
   * Integrity conditions are:
   *   - if there are no frames, the value must be -1
   *   - if there are some frames, the value must be from set
   *     {0, 1, ..., nFrames - 1}
   *
   * @param {state} state
   * @param {number} payload
   */
  setActiveFrame: (state, payload) => {
    const nFrames = getters.getNFrames(state);

    if (nFrames === 0) {
      state.activeFrame = -1;
    } else {
      state.activeFrame = Math.min(nFrames - 1, Math.max(0, payload));
    }
  },

  /**
   * Setter for the `frames` property
   *
   * Active frame is reset to 0 after the frames are modified
   *
   * @param {state} state
   * @param {Array} payload
   */
  setFrames: (state, payload) => {
    state.frames = payload;
    mutations.setActiveFrame(state, 0);
  },

  /**
   * Sets the active frame to the next frame
   *
   * @param {state} state
   */
  nextFrame: (state) => mutations.setActiveFrame(state, getters.getActiveFrame(state) + 1),

  /**
   * Sets the active frame to the previous frame
   *
   * @param {state} state
   */
  prevFrame: (state) => mutations.setActiveFrame(state, getters.getActiveFrame(state) - 1),

  /**
   * Sets the active frame to the last frame
   *
   * @param {state} state
   */
  lastFrame: (state) => mutations.setActiveFrame(state, getters.getNFrames(state)),

  /**
   * Sets the active frame to the first frame
   *
   * @param {state} state
   */
  firstFrame: (state) => mutations.setActiveFrame(state, 0),

  /**
   * Sets the timestamp of the last response from the visualization engine
   *
   * @param {state} state
   * @param {number} payload
   */
  setResponseTimestamp: (state, payload) => (state.responseTimestamp = payload),

  /**
   * Starts the waiting process for the REST API response
   *
   * @param {state} state
   */
  startLoading: (state) => (state.loading = true),

  /**
   * Stops the waiting process for the REST API response
   *
   * @param {state} state
   */
  stopLoading: (state) => (state.loading = false),

  /**
   * Enables the line highlighting
   *
   * @param {state} state
   */
  enableHighlight: (state) => (state.highlightEnabled = true),

  /**
   * Disables the line highlighting
   *
   * @param {state} state
   */
  disableHighlight: (state) => (state.highlightEnabled = false),

  /**
   * Toggles the line highlighting
   *
   * @param {state} state
   */
  toggleHighlight: (state) =>
    getters.getHighlightEnabled(state)
      ? mutations.disableHighlight(state)
      : mutations.enableHighlight(state),

  /**
   * Enables the cache
   *
   * @param {state} state
   */
  enableCache: (state) => (state.cacheEnabled = true),

  /**
   * Disables the cache
   *
   * @param {state} state
   */
  disableCache: (state) => (state.cacheEnabled = false),

  /**
   * Toggles the cache
   *
   * @param {state} state
   */
  toggleCache: (state) =>
    getters.getCacheEnabled(state) ? mutations.disableCache(state) : mutations.enableCache(state),

  /**
   * Resets the state of the visualizer
   *
   * @param {state} state
   */
  reset: (state) => {
    state.res = INITIAL_RES();
    state.err = INITIAL_ERR();
    state.elapsed = INITIAL_ELAPSED();
    state.activeFrame = INITIAL_ACTIVE_FRAME();
    state.frames = INITIAL_FRAMES();
    state.loading = INITIAL_LOADING();

    // Do not reset highlight enabled
    // Do not reset cache enabled
  },
};

const modules = {
  player,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules,
};

<template>
  <icon-button
    :disabled="loading"
    :icon="icon"
    :icon-size="iconSize"
    :text="textContent"
    @click="play"
  />
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'play-button',
  props: {
    iconSize: {
      type: Number,
      default: undefined,
    },
  },
  computed: {
    ...mapGetters({
      loading: 'visualizer/getLoading',
      isPlaying: 'visualizer/getIsPlaying',
      isLastFrame: 'visualizer/isLastFrame',
    }),
    icon() {
      return this.isPlaying ? 'feather-pause' : 'feather-play';
    },
    textContent() {
      return this.isPlaying ? 'Stop' : 'Play';
    },
  },
  methods: {
    ...mapMutations({
      togglePlaying: 'visualizer/togglePlaying',
      firstFrame: 'visualizer/firstFrame',
    }),
    play() {
      if (!this.isPlaying && this.isLastFrame) {
        this.firstFrame();
      }

      this.togglePlaying();
    },
  },
};
</script>

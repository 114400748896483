<template>
  <panel hide-header hide-footer class="code-panel">
    <code-editor />
  </panel>
</template>

<script>
import Panel from './Panel.vue';
import CodeEditor from '../CodeEditor.vue';

export default {
  name: 'code-panel',
  components: {
    Panel,
    CodeEditor,
  },
};
</script>

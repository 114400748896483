<template>
  <div class="d-flex align-items-center">
    <h1 class="h4 mt-0 mb-0 me-2">{{ algorithmName }}</h1>

    <icon-button
      icon="feather-star"
      :icon-size="16"
      class="btn-dark rounded-0 px-2 ms-1"
      @click="$modal.alert('Not implemented')"
    />

    <icon-button
      icon="feather-git-branch"
      :icon-size="16"
      class="btn-dark rounded-0 px-2"
      @click="$modal.alert('Not implemented')"
    />

    <icon-button
      icon="feather-share"
      :icon-size="16"
      class="btn-dark rounded-0 px-2"
      @click="$modal.alert('Not implemented')"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'algorithm-detail-header',
  computed: {
    ...mapGetters({
      algorithm: 'algorithm/getAlgorithm',
    }),
    algorithmName() {
      return this.algorithm ? this.algorithm.name : 'Loading ...';
    },
  },
};
</script>

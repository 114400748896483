<template>
  <ul class="featured-algorithms-list">
    <template v-for="{ namespace, slug, name } in algorithms">
      <algorithm-detail-link
        :key="`home-sidebar-alg-${namespace}-${slug}`"
        :namespace="namespace"
        :slug="slug"
        :name="name"
      />
    </template>
  </ul>
</template>

<script>
import connector from '../../api/connector';
import AlgorithmDetailLink from '../links/AlgorithmDetailLink.vue';

export default {
  name: 'featured-algorithms-list',
  components: {
    AlgorithmDetailLink,
  },
  data() {
    return {
      loading: false,
      algorithms: [],
    };
  },
  methods: {
    async fetchFeaturedAlgorithms() {
      try {
        this.loading = true;

        const { algorithms } = await connector.get('/algorithm/list');
        this.algorithms = algorithms;
      } finally {
        this.loading = false;
      }
    },
  },
  created() {
    this.fetchFeaturedAlgorithms();
  },
};
</script>

<template>
  <button class="btn" @click="changeFontSize">
    <small>Font size: {{ fontSize }}px</small>
  </button>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'font-size-button',
  computed: {
    ...mapGetters({
      fontSize: 'code-editor/getFontSize',
    }),
  },
  methods: {
    changeFontSize() {
      this.$modal.alert('Not implemented');
    },
  },
};
</script>

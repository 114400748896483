// Layouts
import SidebarLayout from '../views/layouts/sidebar-layout/SidebarLayout.vue';

// Sidebars
import BrowseAlgorithmsSidebar from '../views/sidebars/BrowseAlgorithmsSidebar.vue';

// Headers
import WelcomeHeader from '../views/headers/WelcomeHeader.vue';
import AlgorithmDetailHeader from '../views/headers/AlgorithmDetailHeader.vue';
import NewAlgorithmHeader from '../views/headers/NewAlgorithmHeader.vue';

// Pages
import Home from '../views/pages/Home.vue';
import Algorithm from '../views/pages/Algorithm.vue';
import NewAlgorithm from '../views/pages/NewAlgorithm.vue';

const routes = [
  {
    path: '/',
    name: 'SidebarLayout',
    component: SidebarLayout,
    children: [
      {
        path: '/',
        name: 'Welcome',
        components: {
          default: Home,
          header: WelcomeHeader,
          sidebar: BrowseAlgorithmsSidebar,
        },
      },
      {
        path: '/algorithm/detail/:namespace/:slug/:version',
        name: 'Algorithm',
        components: {
          default: Algorithm,
          header: AlgorithmDetailHeader,
          sidebar: BrowseAlgorithmsSidebar,
        },
      },
      {
        path: '/algorithm/new',
        name: 'New algorithm',
        components: {
          default: NewAlgorithm,
          header: NewAlgorithmHeader,
          sidebar: BrowseAlgorithmsSidebar,
        },
      },
    ],
  },
];

export default routes;

<template>
  <div class="frames-control">
    <icon-button
      :class="buttonClass"
      icon="feather-chevrons-left"
      :icon-size="iconSize"
      :disabled="loading"
      @click="firstFrame"
    />
    <icon-button
      :class="buttonClass"
      icon="feather-chevron-left"
      :icon-size="iconSize"
      :disabled="loading"
      @click="prevFrame"
    />
    <div
      :style="barStyle"
      class="frames-control__pick-frame"
      ref="pickFrame"
      @mousedown="pickFrame"
      @mousemove="pickFrame"
    >
      <small v-if="loading">Building</small>
      <small v-else>{{ activeFrame + 1 }} / {{ nFrames }}</small>
    </div>
    <icon-button
      :class="buttonClass"
      icon="feather-chevron-right"
      :icon-size="iconSize"
      :disabled="loading"
      @click="nextFrame"
    />
    <icon-button
      :class="buttonClass"
      icon="feather-chevrons-right"
      :icon-size="iconSize"
      :disabled="loading"
      @click="lastFrame"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'frames-control',
  props: {
    barCol: {
      type: String,
      default: 'darkcyan',
    },
    buttonClass: {
      type: String,
      default: '',
    },
    iconSize: {
      type: Number,
      default: undefined,
    },
  },
  computed: {
    ...mapGetters({
      loading: 'visualizer/getLoading',
      nFrames: 'visualizer/getNFrames',
      activeFrame: 'visualizer/getActiveFrame',
    }),
    barPerc() {
      return Math.round(((this.activeFrame + 1) / this.nFrames) * 100);
    },
    barStyle() {
      return {
        background: `linear-gradient(90deg, ${this.barCol} 0%, ${this.barCol} ${this.barPerc}%, transparent ${this.barPerc}%, transparent 100%)`,
      };
    },
  },
  methods: {
    ...mapMutations({
      setActiveFrame: 'visualizer/setActiveFrame',
      nextFrame: 'visualizer/nextFrame',
      prevFrame: 'visualizer/prevFrame',
      lastFrame: 'visualizer/lastFrame',
      firstFrame: 'visualizer/firstFrame',
    }),
    pickFrame(e) {
      if (this.nFrames == 0) {
        return; // Ignore when no frames
      }

      if (e.type === 'mousemove' && (e.buttons === undefined ? e.which !== 1 : e.buttons !== 1)) {
        return; // Ignore mousemove when left button not clicked
      }

      const maxWidth = this.$refs.pickFrame.getBoundingClientRect().width;
      const delta = e.layerX / maxWidth;
      const frame = Math.round(this.nFrames * delta - 0.5);

      this.setActiveFrame(frame);
    },
  },
};
</script>

<style lang="scss">
.frames-control {
  display: flex;
  align-items: stretch;

  &__pick-frame {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 150px;

    cursor: w-resize;
    user-select: none;
  }
}
</style>

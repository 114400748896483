<template>
  <div class="zoomable">
    <div class="zoomable-element">
      <slot></slot>
    </div>

    <template v-if="controls">
      <div class="zoomable-controls">
        <button @click="center" class="btn btn-dark">
          <icon style="transform: scale(0.85)" icon="feather-map-pin" />
        </button>
      </div>
    </template>
  </div>
</template>

<script>
import panzoom from 'panzoom';

export default {
  name: 'zoomable',
  props: {
    // Components properties
    controls: {
      type: Boolean,
      default: false,
    },
    // Control properties
    enabled: {
      type: Boolean,
      default: true,
    },
    reset: {
      default: null,
    },
    // Panzoom properties
    initialZoom: {
      type: Number,
      default: 1,
    },
    initialX: {
      type: Number,
      default: 0,
    },
    initialY: {
      type: Number,
      default: 0,
    },
    minZoom: {
      type: Number,
      default: 0.5,
    },
    maxZoom: {
      type: Number,
      default: 5,
    },
    zoomSpeed: {
      type: Number,
      default: 0.15,
    },
    zoomDoubleClickSpeed: {
      type: Number,
      default: 1, // <1: zoom out+ 1: disabled; >1: zoom in
    },
    smoothScroll: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      panzoom: null,
    };
  },
  watch: {
    reset: 'center',
  },
  methods: {
    plus() {},
    center() {
      this.panzoom.zoomAbs(this.initialX, this.initialY, this.initialZoom);
      this.panzoom.moveTo(this.initialX, this.initialY);
    },
    minus() {},
  },
  mounted() {
    const nSlots = this.$slots.default.length;

    if (nSlots === 0) {
      throw new Error('Too few slots. Exactly one slot is expected');
    }

    if (nSlots > 1) {
      throw new Error('Too many slots. Exactly one slot is expected');
    }

    this.panzoom = panzoom(this.$slots.default[0].elm, {
      initialZoom: this.initialZoom,
      initialX: this.initialX,
      initialY: this.initialY,
      minZoom: this.minZoom,
      maxZoom: this.maxZoom,
      zoomSpeed: this.zoomSpeed,
      zoomDoubleClickSpeed: this.zoomDoubleClickSpeed,
      smoothScroll: this.smoothScroll,
    });
  },
};
</script>

<style lang="scss">
$zoomable-side-padding: 10px;
$zoomable-buttons-gap: 4px;

.zoomable {
  position: relative;
}

.zoomable-element {
  width: 100%;
  height: 100%;
}

.zoomable-controls {
  position: absolute;
  bottom: $zoomable-side-padding - $zoomable-buttons-gap;
  right: $zoomable-side-padding;

  button {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 28px;
    height: 28px;

    padding: 0;
    margin-bottom: $zoomable-buttons-gap;

    .feather {
      width: 1em;
      height: 1em;
    }
  }
}

.zoomable-display {
  position: absolute;
  top: $zoomable-side-padding;
  left: $zoomable-side-padding;
}
</style>

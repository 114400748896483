<template>
  <zoomable :reset="timestamp" display controls class="visualizer-graph">
    <svg
      class="visualizer-graph-svg"
      ref="svg"
      :viewBox="`0 0 ${viewboxSize} ${viewboxSize}`"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g v-if="comp">
        <template>
          <!-- Edges -->
          <template v-for="(edge, i) in edges">
            <graph-edge
              :key="`visualizer-graph-${component}-edge-(${edge[0]},${edge[1]})`"
              :index="i"
              :x1="nodePositions[edge[0]].x"
              :y1="nodePositions[edge[0]].y"
              :x2="nodePositions[edge[1]].x"
              :y2="nodePositions[edge[1]].y"
              :color="getEdgeColor(edge)"
              :shape="getEdgeShape(edge)"
              :label="getEdgeLabel(edge)"
              :size="getEdgeSize(edge)"
              :is-directed="isDirected"
              :target-node-size="getNodeSize(edge[1])"
            />
          </template>

          <!-- Nodes -->
          <template v-for="node in nodes">
            <graph-node
              :key="`visualizer-graph-${component}-node-${node}`"
              :x="nodePositions[node].x"
              :y="nodePositions[node].y"
              :color="getNodeColor(node)"
              :shape="getNodeShape(node)"
              :label="getNodeLabel(node)"
              :size="getNodeSize(node)"
            />
          </template>
        </template>
      </g>
    </svg>
  </zoomable>
</template>

<script>
import Zoomable from '../../Zoomable';
import GraphNode from './GraphNode';
import GraphEdge from './GraphEdge';
import { mapGetters } from 'vuex';

export default {
  name: 'visualizer-graph',
  props: {
    component: {
      type: Number,
      required: true,
    },
    viewboxSize: {
      type: Number,
      default: 400,
      validate: (s) => s > 0,
    },
  },
  components: {
    Zoomable,
    GraphNode,
    GraphEdge,
  },
  computed: {
    ...mapGetters({
      frames: 'visualizer/getFrames',
      activeFrame: 'visualizer/getActiveFrame',
      timestamp: 'visualizer/getResponseTimestamp',
    }),
    frame() {
      if (this.activeFrame in this.frames) {
        return this.frames[this.activeFrame];
      }

      return null;
    },
    comp() {
      return this.frame.components[this.component];
    },
    type() {
      return this.comp.type;
    },
    isDirected() {
      return this.type === 'DiGraph';
    },
    nodes() {
      return this.comp.nodes;
    },
    edges() {
      return this.comp.edges;
    },
    style() {
      return this.comp.style;
    },
    allNodes() {
      // Todo: move this computation to the server, where it can be optimized

      const allNodes = Array.from(
        this.frames.reduce((set, frame) => {
          if (this.component in frame.components) {
            frame.components[this.component].nodes.forEach((node) => set.add(node));
          }

          return set;
        }, new Set())
      );

      let comparator = undefined;

      if (allNodes.every((node) => !isNaN(Number(node)))) {
        comparator = (a, b) => a - b;
      }

      return allNodes.sort(comparator);
    },
    nodePositions() {
      const positions = {};

      this.allNodes.forEach((node, index) => {
        const rads = Math.PI * 2 * (index / this.allNodes.length - 0.25);

        positions[node] = {
          x: Math.cos(rads) * this.viewboxSize * 0.4 + this.viewboxSize / 2,
          y: Math.sin(rads) * this.viewboxSize * 0.4 + this.viewboxSize / 2,
        };
      });

      return positions;
    },
  },
  methods: {
    getNodeStyle(v, style) {
      if (!this.style[style]) {
        return undefined;
      }

      return this.style[style][v];
    },
    getNodeColor(node) {
      return this.getNodeStyle(node, 'node_colors');
    },
    getNodeShape(node) {
      return this.getNodeStyle(node, 'node_shapes');
    },
    getNodeSize() {
      return this.viewboxSize / 45;
    },
    getNodeLabel(node) {
      return this.getNodeStyle(node, 'node_labels') || String(node);
    },
    getEdgeStyle([u, v], style) {
      if (!this.style[style]) {
        return undefined;
      }

      if (!this.style[style][u]) {
        return undefined;
      }

      return this.style[style][u][v];
    },
    getEdgeColor(edge) {
      return this.getEdgeStyle(edge, 'edge_colors');
    },
    getEdgeShape(edge) {
      return this.getEdgeStyle(edge, 'edge_shapes');
    },
    getEdgeSize() {
      return this.viewboxSize / 200;
    },
    getEdgeLabel(edge) {
      return this.getEdgeStyle(edge, 'edge_labels') || '';
    },
  },
};
</script>

<style lang="scss">
.visualizer-graph {
  background-color: #282c34;

  overflow: hidden;
  user-select: none;
}

.visualizer-graph-svg {
  width: 100%;
  height: 100%;
}
</style>

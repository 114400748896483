<template>
  <icon-button
    :icon="icon"
    :icon-size="iconSize"
    :text="textContent"
    :small="small"
    :style="computedStyle"
    @click="toggleCache"
  />
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'cache-button',
  props: {
    iconSize: {
      type: Number,
      default: undefined,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      cacheEnabled: 'visualizer/getCacheEnabled',
    }),
    icon() {
      return `feather-cloud${this.cacheEnabled ? '' : '-off'}`;
    },
    textContent() {
      return this.cacheEnabled ? 'Cache On' : 'Cache Off';
    },
    computedStyle() {
      return {
        'margin-right': this.cacheEnabled ? '1px' : '0',
      };
    },
  },
  methods: {
    ...mapMutations({
      toggleCache: 'visualizer/toggleCache',
    }),
  },
};
</script>

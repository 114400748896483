<template>
  <algorithm-editor />
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import AlgorithmEditor from '../../components/AlgorithmEditor.vue';

export default {
  name: 'page-new-algorithm',
  components: {
    AlgorithmEditor,
  },
  methods: {
    ...mapActions({
      visualizeAlgorithm: 'visualizer/visualizeAlgorithm',
    }),
    ...mapMutations({
      setCode: 'code-editor/setCode',
      lastFrame: 'visualizer/lastFrame',
    }),
    async getBoilerplateCode() {
      // todo: move this to the server.
      return (
        `
# Components
G = engine.Graph([(i+1, i+2, {'w':i}) for i in range(20)] + [(21, 1, {'w':20})])

# Variables
a = [1, 5, 12, 17]

# Visualization                      # G.{color,label,shape,scale}_{nodes,edges}_by
G.shape_nodes_by(a)                  # Use a container for binary groups
G.label_edges_by(prop='w')           # Use the node's property value directly
G.color_nodes_by(lambda v, G: v % 7) # Use a lambda function to define custom logic

# Algorithm
# Your code goes here ...

print('Done!')
`.trim() + '\n'
      );
    },
  },
  async mounted() {
    const code = await this.getBoilerplateCode();

    this.setCode(code);
    await this.visualizeAlgorithm({ code, enableCache: true });

    this.lastFrame();
  },
};
</script>

<template>
  <icon-button
    :icon="icon"
    :icon-size="iconSize"
    :text="textContent"
    :small="small"
    :style="computedStyle"
    @click="toggleHighlight"
  />
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'highlight-button',
  props: {
    iconSize: {
      type: Number,
      default: undefined,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      highlightEnabled: 'visualizer/getHighlightEnabled',
    }),
    icon() {
      return `feather-zap${this.highlightEnabled ? '' : '-off'}`;
    },
    textContent() {
      return this.highlightEnabled ? 'Highlight On' : 'Highlight Off';
    },
    computedStyle() {
      return {
        'margin-right': this.highlightEnabled ? '1px' : '0',
      };
    },
  },
  methods: {
    ...mapMutations({
      toggleHighlight: 'visualizer/toggleHighlight',
    }),
  },
};
</script>

<template>
  <div :style="computedStyle" ref="wrapper" class="code-editor"></div>
</template>

<script>
import { basicSetup } from '@codemirror/basic-setup';
import { keymap, EditorView } from '@codemirror/view';
import { EditorState, Compartment } from '@codemirror/state';
import { defaultTabBinding } from '@codemirror/commands';
import { python } from '@codemirror/lang-python';
import oneDark from '../themes/one-dark';
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'code-editor',
  metaInfo() {
    if (this.highlightEnabled) {
      const cssText = this.activeLines
        .map(
          (line) => `
          .code-editor .cm-line:nth-of-type(${line}) {
            background-color: #008b8b;
          }`
        )
        .join('\n');

      return { style: [{ type: 'text/css', cssText }] };
    }

    return {};
  },
  computed: {
    ...mapGetters({
      editor: 'code-editor/getEditorView',
      fontSize: 'code-editor/getFontSize',
      activeLines: 'visualizer/getActiveLines',
      highlightEnabled: 'visualizer/getHighlightEnabled',
    }),
    computedStyle() {
      return {
        'font-size': this.fontSize + 'px',
      };
    },
  },
  methods: {
    ...mapMutations({
      setEditorView: 'code-editor/setView',
    }),
  },
  mounted() {
    const language = new Compartment();
    const tabSize = new Compartment();

    const initialState = EditorState.create({
      extensions: [
        basicSetup,
        language.of(python()),
        tabSize.of(EditorState.tabSize.of(2)),
        keymap.of([defaultTabBinding]),
        oneDark,
      ],
    });

    const editorView = new EditorView({
      state: initialState,
      parent: this.$refs.wrapper,
    });

    this.setEditorView(editorView);
  },
};
</script>

<style lang="scss">
.cm-editor,
.code-editor {
  height: 100%;
}

.cm-editor {
  font-size: inherit;

  outline: 0 !important;
}
</style>

<template>
  <div class="algorithm-editor">
    <split split-id="algorithm-editor" :gutter-size="1" :initial-sizes="[50, 50]" save-state>
      <code-panel class="algorithm-editor__code-panel" />
      <visualizer-panel class="algorithm-editor__visualizer-panel" />
    </split>
  </div>
</template>

<script>
import Split from './Split.vue';
import VisualizerPanel from './panels/VisualizerPanel.vue';
import CodePanel from './panels/CodePanel.vue';

export default {
  name: 'algorithm-editor',
  components: {
    Split,
    VisualizerPanel,
    CodePanel,
  },
};
</script>

<style lang="scss">
.algorithm-editor {
  height: 100%;

  &__visualizer-panel,
  &__code-panel {
    height: 100%;
  }
}
</style>

import axios from 'axios';
import { API_BASE, API_TIMEOUT } from '../constants/environment';
import modal from '../plugins/modal/modal-component';
import session from './session';
import { isObject } from 'lodash';
import {
  ERROR_CODE_AUTHORIZATION_HEADER_MISSING,
  ERROR_CODE_JWT_INVALID,
  ERROR_CODE_UNKNOWN,
} from './error-codes';

/**
 * Returns the error message which should be displayed.
 *
 * @param {{
 *   info: string,
 *   msg: string
 * }}
 *
 * @returns {string}
 */
const getErrorMsg = ({ info, msg }) => info || msg || 'Unknown error';

/**
 * Handles an API error.
 *
 * @param {{
 *   code: number,
 *   msg: string,
 *   info: string,
 * }}
 */
const handleError = ({ code, msg, info }) => {
  if (code === ERROR_CODE_AUTHORIZATION_HEADER_MISSING || code === ERROR_CODE_JWT_INVALID) {
    session.logout();
    modal.alert('You have been logged out');
  } else {
    modal.alert(getErrorMsg({ info, msg }));
  }
};

/**
 * Intercepts a request and adds an Authorization header if the JWT is set.
 *
 * @param {*} cfg
 */
const requestInterceptor = (cfg) => {
  connectorState.lastRequest = Date.now();

  if (session.jwt) {
    cfg.headers.Authorization = `Bearer ${session.jwt}`;
  }

  return cfg;
};

/**
 * Intercepts a success response and returns the data contents.
 */
const responseSuccessInterceptor = ({ data }) => {
  if (!data.ok) {
    handleError(data.error);
  }

  return data.data;
};

/**
 * Intercepts an error response an displays an error message. Throws a custom
 * version of the original error.
 */
const responseErrorInterceptor = (err) => {
  if (
    isObject(err) &&
    isObject(err.response) &&
    isObject(err.response.data) &&
    isObject(err.response.data.error)
  ) {
    handleError(err.response.data.error);
    throw new Error(getErrorMsg(err.response.data.error));
  } else if (err instanceof Error) {
    handleError({ code: ERROR_CODE_UNKNOWN, msg: err.message, info: null });
    throw err;
  } else {
    handleError({ code: ERROR_CODE_UNKNOWN, msg: String(err), info: null });
    throw new Error(String(err));
  }
};

/**
 * The state of the connector.
 */
export const connectorState = {
  lastRequest: null,
};

/**
 * The connector.
 */
const connector = axios.create({
  timeout: API_TIMEOUT,
  baseURL: API_BASE,
});

connector.interceptors.request.use(requestInterceptor);
connector.interceptors.response.use(responseSuccessInterceptor, responseErrorInterceptor);

export default connector;

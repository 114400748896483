<template>
  <modal :name="LOGIN_MODAL_NAME" heading="Login" acceptButtonText="Login" @submit="login">
    <div class="form-group">
      <label for="username-or-email">Username or email</label>
      <input v-model="emailOrUsername" class="form-control" type="text" />
    </div>

    <div class="form-group mt-3">
      <label for="password">Password</label>
      <input v-model="password" class="form-control" type="password" />
    </div>

    <p v-if="error" class="mt-3 text-danger">{{ error }}</p>
  </modal>
</template>

<script>
import session from '../../api/session';
import Modal from './Modal.vue';
import { LOGIN_MODAL_NAME } from '../../constants/constants';

export default {
  components: {
    Modal,
  },
  data() {
    return {
      LOGIN_MODAL_NAME,
      loading: false,
      error: null,
      emailOrUsername: '',
      password: '',
    };
  },
  methods: {
    close() {
      this.$store.commit('modal/close', this.LOGIN_MODAL_NAME);
    },
    login() {
      if (this.loading) {
        return;
      }

      this.loading = true;
      this.error = null;

      session
        .login(this.emailOrUsername, this.password)
        .then(() => this.close())
        .catch((error) => (this.error = error))
        .finally(() => (this.loading = false));
    },
  },
};
</script>

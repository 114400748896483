<template>
  <div id="app">
    <router-view></router-view>
    <login-modal />
  </div>
</template>

<script>
import LoginModal from './components/modal/LoginModal.vue';

export default {
  name: 'app',
  components: {
    LoginModal,
  },
  metaInfo: {
    title: 'See Your Graph Algorithm',
    titleTemplate: '%s | SyGA',
  },
};
</script>

<style lang="scss">
*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  margin: 0;

  background-color: #282c34;

  overflow-y: hidden;
}

#app {
  color: #ddd;
}
</style>

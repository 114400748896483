<template>
  <div class="algorithm-visualizer">
    <template v-if="error">
      <div class="algorithm-visualizer__error">
        <strong>
          <pre>ERROR: {{ error }}</pre>
        </strong>
      </div>
    </template>

    <split
      v-if="frame"
      save-state
      :split-id="`page-home-visualizer-${components.length}`"
      direction="vertical"
      :initial-sizes="[75, 25]"
      :gutter-size="1"
    >
      <template v-for="({ type }, index) in components">
        <visualizer-graph
          :key="`visualizer-${index}`"
          v-if="type === 'Graph' || type === 'DiGraph'"
          :component="index"
        />
      </template>

      <visualizer-console />
    </split>
    <div v-else-if="response === 'error'">Error</div>
    <div v-else>Loading ...</div>
  </div>
</template>

<script>
import VisualizerConsole from './console/VisualizerConsole';
import VisualizerGraph from './graph/VisualizerGraph';
import Split from '../Split';
import { mapGetters } from 'vuex';

export default {
  name: 'algorithm-visualizer',
  components: {
    VisualizerConsole,
    VisualizerGraph,
    Split,
  },
  computed: {
    ...mapGetters({
      frames: 'visualizer/getFrames',
      activeFrame: 'visualizer/getActiveFrame',
      response: 'visualizer/getRes',
      result: 'visualizer/getRes',
      error: 'visualizer/getErr',
      timestamp: 'visualizer/getResponseTimestamp',
    }),
    frame() {
      if (this.activeFrame in this.frames) {
        return this.frames[this.activeFrame];
      }

      return null;
    },
    components() {
      if (this.frame === null) {
        return [];
      }

      return this.frame.components;
    },
  },
  watch: {
    timestamp() {
      if (this.result !== 'success') {
        this.$modal.alert(`Got ${this.result} result\nError: ${this.error}`);
      }
    },
  },
};
</script>

<style lang="scss">
.algorithm-visualizer {
  position: relative;

  height: 100%;

  background-color: #282c34;

  &__error {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;

    width: 100%;
    height: auto;

    padding: 5px;
  }
}
</style>

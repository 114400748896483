<template>
  <router-link class="algorithm-detail-link" :to="url">
    <slot>
      <span>{{ name }}</span>
    </slot>
  </router-link>
</template>

<script>
export default {
  name: 'algorithm-detail-link',
  props: {
    namespace: {
      type: String,
      required: true,
    },
    slug: {
      type: String,
      required: true,
    },
    version: {
      type: String,
      default: 'latest',
    },
    name: {
      type: String,
    },
  },
  computed: {
    url() {
      return `/algorithm/detail/${this.namespace}/${this.slug}/${this.version}`;
    },
  },
};
</script>

<template>
  <g v-if="!isHidden">
    <defs>
      <marker
        :id="`arrowhead-${index}`"
        markerWidth="4"
        markerHeight="4"
        refX="0"
        refY="1.75"
        orient="auto"
        :fill="actualColor"
      >
        <polygon points="0 0, 4 2, 0 3.5" />
      </marker>
    </defs>
    <line
      :x1="x1"
      :y1="y1"
      :x2="x2 - Number(isDirected) * (targetNodeSize + 8) * cos"
      :y2="y2 + Number(isDirected) * (targetNodeSize + 8) * sin"
      :stroke="actualColor"
      :stroke-width="size"
      :stroke-dasharray="strokeDasharray"
      :marker-end="isDirected ? `url(#arrowhead-${index})` : undefined"
    />
    <text
      :x="xCenter"
      :y="yCenter"
      :transform="
        `
        translate(${this.xTranslate} ${this.yTranslate})
        rotate(${this.labelRotation} ${this.xCenter} ${this.yCenter})`
      "
      font-size="10"
      fill="#aaa"
      text-anchor="middle"
    >
      {{ label }}
    </text>
  </g>
</template>

<script>
const DEFAULT_COLOR = '#aaa';
const DEFAULT_SHAPE = 'solid';

export default {
  name: 'visualizer-graph-shape',
  props: {
    index: {
      type: Number,
      required: true,
    },
    x1: {
      type: Number,
      required: true,
    },
    y1: {
      type: Number,
      required: true,
    },
    x2: {
      type: Number,
      required: true,
    },
    y2: {
      type: Number,
      required: true,
    },
    color: {
      type: String,
      default: DEFAULT_COLOR,
    },
    shape: {
      type: String,
      default: DEFAULT_SHAPE,
    },
    size: {
      type: Number,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    isDirected: {
      type: Boolean,
      required: true,
    },
    targetNodeSize: {
      type: Number,
      required: true,
    },
  },
  computed: {
    actualColor() {
      return this.color || DEFAULT_COLOR;
    },
    actualShape() {
      return (this.shape || DEFAULT_SHAPE).toLowerCase();
    },
    isHidden() {
      return this.actualShape === 'hidden';
    },
    strokeDasharray() {
      switch (this.actualShape) {
        case 'dashed':
          return '6 6';

        case 'dotted':
          return '2 6';

        default:
          return '0 0';
      }
    },
    xCenter() {
      return (this.x1 + this.x2) / 2;
    },
    yCenter() {
      return (this.y1 + this.y2) / 2;
    },
    xDiff() {
      return this.x2 - this.x1;
    },
    yDiff() {
      return this.y1 - this.y2;
    },
    length() {
      return Math.sqrt(this.xDiff ** 2 + this.yDiff ** 2);
    },
    sin() {
      return this.yDiff / this.length;
    },
    cos() {
      return this.xDiff / this.length;
    },
    labelRotation() {
      return (this.cos >= 0 ? -1 : 1) * (Math.asin(this.sin) / Math.PI) * 180;
    },
    xRotated() {
      return this.xCenter * this.cos + this.yCenter * this.sin;
    },
    yRotated() {
      return this.xCenter * this.sin - this.yCenter * this.cos;
    },
    xTranslate() {
      return (this.cos >= 0 ? -1 : 1) * this.sin * 4;
    },
    yTranslate() {
      return -Math.abs(this.cos) * 4;
    },
  },
};
</script>

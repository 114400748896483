<template>
  <div class="panel">
    <header v-if="!hideHeader" :style="headerStyle" class="panel__header">
      <slot name="header"></slot>
    </header>

    <main :style="mainStyle" class="panel__main">
      <slot></slot>
    </main>

    <footer v-if="!hideFooter" :style="footerStyle" class="panel__footer">
      <slot name="footer"></slot>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'panel',
  props: {
    hideHeader: {
      type: Boolean,
      default: false,
    },
    hideFooter: {
      type: Boolean,
      default: false,
    },
    headerSize: {
      type: Number,
      default: 34, // px
    },
    footerSize: {
      type: Number,
      default: 24, // px
    },
  },
  computed: {
    actualHeaderSize() {
      return this.hideHeader ? 0 : this.headerSize;
    },
    actualFooterSize() {
      return this.hideFooter ? 0 : this.footerSize;
    },
    headerStyle() {
      return {
        height: `${this.actualHeaderSize}px`,
      };
    },
    footerStyle() {
      return {
        height: `${this.actualFooterSize}px`,
      };
    },
    mainStyle() {
      return {
        height: `calc(100% - ${this.actualHeaderSize}px - ${this.actualFooterSize}px)`,
      };
    },
  },
};
</script>

<style lang="scss">
.panel {
  height: 100%;

  &__header,
  &__footer {
    background-color: #212529;
  }

  &__header {
    display: flex;
    align-items: stretch;

    border-bottom: 1px solid #444;

    .spacer {
      width: 1px;
      height: 100%;

      background-color: #444;
    }
  }

  &__footer {
    display: flex;
    align-items: stretch;

    border-top: 1px solid #444;
  }
}
</style>

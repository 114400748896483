const state = {
  /**
   * A list of opened modal in chronological order.
   *
   * @type {Array<string>}
   */
  opened: [],
};

const getters = {
  /**
   * Returns a functions which for any modal name determines whether the modal
   * is opened or not.
   *
   * @param {state}
   *
   * @returns {(name: string) => boolean}
   */
  isOpened: ({ opened }) => ((name) => opened.includes(name)).bind(opened),

  /**
   * Returns the index of the specified modal.
   *
   * @param {state}
   *
   * @returns {(name: string) => number}
   */
  indexOf: ({ opened }) => ((name) => opened.indexOf(name)).bind(opened),
};

const actions = {};

const mutations = {
  /**
   * Opens the specified modal.
   *
   * @param {state}
   * @param {string} name
   */
  open: ({ opened }, name) => opened.push(name),

  /**
   * Closes the specified modal.
   *
   * @param {state}
   * @param {string} name
   */
  close: ({ opened }, name) => {
    const index = opened.indexOf(name);

    if (index > -1) {
      opened.splice(index, 1);
    }
  },

  /**
   * Closes the topmost modal.
   *
   * @param {state}
   */
  closeTopmost: ({ opened }) => opened.pop(),
};

const modules = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules,
};

<template>
  <div class="d-flex align-items-center">
    <h4 class="mt-0 mb-0">Untitled Algorithm</h4>

    <icon-button
      icon="feather-save"
      :icon-size="16"
      class="btn-dark rounded-0 px-2 ms-1"
      @click="$modal.alert('Not implemented')"
    />
  </div>
</template>

<script>
export default {
  name: 'new-algorithm-header',
};
</script>

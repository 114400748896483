import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import globalPlugins from './global-plugins';

import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

Vue.config.productionTip = false;

Vue.use(globalPlugins);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

// todo: maybe use setInterval and setTimeout instead

const state = {
  /**
   * `true` if the player has already been initialized
   *
   * @type {Boolean}
   */
  isPlayerInitialized: false,

  /**
   * `true` if the player is playing
   *
   * @type {Boolean}
   */
  isPlaying: false,

  /**
   * The last timestamp produced by window.requestAnimationFrame
   *
   * @type {Number}
   */
  timestamp: null,

  /**
   * The timestamp of the last tick
   *
   * @type {Number}
   */
  lastTick: null,

  /**
   * The duration in milliseconds between two ticks
   *
   * @type {Number}
   */
  tickPeriod: 800,
};

const getters = {
  /**
   * Returns `true` if the player has already been initialized
   *
   * @param {state} state
   */
  getIsPlayerInitialized: ({ isPlayerInitialized }) => isPlayerInitialized,

  /**
   * Returns `true` if the player is playing
   *
   * @param {state} state
   */
  getIsPlaying: ({ isPlaying }) => isPlaying,

  /**
   * Returns the last timestamp produced by window.requestAnimationFrame
   *
   * @param {state} state
   */
  getTimestamp: ({ timestamp }) => timestamp,

  /**
   * Returns `true` if a tick should be made right now
   *
   * @param {state} state
   */
  shouldDoTick: ({ tickPeriod, lastTick, timestamp }) => lastTick + tickPeriod <= timestamp,
};

const actions = {
  /**
   * The main loop function
   */
  mainLoop: ({ getters, commit, dispatch }, timestamp) => {
    commit('setTimestamp', timestamp);

    if (timestamp && getters.getIsPlaying && getters.shouldDoTick) {
      dispatch('doPlayerTick');
    }

    window.requestAnimationFrame((timestamp) => dispatch('mainLoop', timestamp));
  },

  /**
   * Used to do one tick - moves to the next frame and saves the tick timestamp
   */
  doPlayerTick: ({ getters, commit }) => {
    commit('setLastTick', getters.getTimestamp);
    commit('nextFrame');

    if (getters.isLastFrame) {
      commit('stopPlaying');
    }
  },

  /**
   * Initializes the player
   */
  initializePlayer: ({ getters, commit, dispatch }) => {
    if (!getters.getIsPlayerInitialized) {
      commit('markPlayerInitialized');
      dispatch('mainLoop');
    }
  },
};

const mutations = {
  /**
   * Marks the player as initialized
   *
   * @param {state} state
   */
  markPlayerInitialized: (state) => (state.isPlayerInitialized = true),

  /**
   * Sets the current timestamp
   *
   * @param {state} state
   * @param {Number} payload
   */
  setTimestamp: (state, payload) => (state.timestamp = payload),

  /**
   * Sets the timestamp of the last tick
   *
   * @param {state} state
   * @param {Number} payload
   */
  setLastTick: (state, payload) => (state.lastTick = payload),

  /**
   * Stars playing
   *
   * @param {state} state
   */
  startPlaying: (state) => {
    state.lastTick = getters.getTimestamp(state);
    state.isPlaying = true;
  },

  /**
   * Stops playing
   *
   * @param {state} state
   */
  stopPlaying: (state) => (state.isPlaying = false),

  /**
   * Toggles playing
   *
   * @param {state} state
   */
  togglePlaying: (state) =>
    getters.getIsPlaying(state) ? mutations.stopPlaying(state) : mutations.startPlaying(state),
};

const modules = {};

export default {
  state,
  getters,
  actions,
  mutations,
  modules,
};

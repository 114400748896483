<template>
  <div class="sidebar-layout">
    <!-- Header -->
    <header class="sidebar-layout__header d-flex justify-content-between align-items-stretch">
      <div class="left d-flex justify-content-start align-items-center ms-2">
        <router-view name="header" />
      </div>

      <div class="right d-flex justify-content-end align-items-stretch">
        <build-button class="btn-dark py-0" :icon-size="16" />
        <play-button class="btn-dark py-0" :icon-size="16" />
        <frames-navigation button-class="btn-dark py-0" :icon-size="24" />
      </div>
    </header>

    <!-- Sidebar -->
    <aside class="sidebar-layout__sidebar">
      <router-view name="sidebar" />
    </aside>

    <!-- Main -->
    <main class="sidebar-layout__main">
      <router-view />
    </main>

    <!-- Footer -->
    <footer class="sidebar-layout__footer d-flex align-items-stretch">
      <cache-button small class="rounded-0 border-0 text-white py-0 px-2" :icon-size="15" />
      <highlight-button small class="rounded-0 border-0 text-white py-0 px-2" :icon-size="15" />
      <font-size-button class="rounded-0 border-0 text-white py-0 px-2" />
    </footer>
  </div>
</template>

<script>
import Split from '../../../components/Split.vue';
import CacheButton from '../../../components/buttons/CacheButton.vue';
import FontSizeButton from '../../../components/buttons/FontSizeButton.vue';
import HighlightButton from '../../../components/buttons/HighlightButton.vue';
import BuildButton from '../../../components/buttons/BuildButton.vue';
import PlayButton from '../../../components/buttons/PlayButton.vue';
import FramesNavigation from '../../../components/FramesNavigation.vue';

export default {
  name: 'sidebar-layout',
  components: {
    Split,
    CacheButton,
    FontSizeButton,
    HighlightButton,
    BuildButton,
    PlayButton,
    FramesNavigation,
  },
};
</script>

<style lang="scss">
$header-height: 39px;
$footer-height: 24px;
$main-height: calc(100vh - #{$header-height + $footer-height});

.sidebar-layout {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: $header-height $main-height $footer-height;
  grid-template-areas:
    'sidebar header'
    'sidebar main'
    'footer footer';

  height: 100vh;
  width: 100%;

  &__header {
    grid-area: header;

    border-bottom: 1px solid #444;

    background-color: #212529;

    .spacer {
      width: 1px;
      height: 100%;

      background-color: #444;
    }
  }

  &__sidebar {
    grid-area: sidebar;

    border-right: 1px solid #444;
  }

  &__main {
    grid-area: main;
  }

  &__footer {
    grid-area: footer;

    background-color: #1e1e1e;

    button:hover {
      background-color: rgba(255, 255, 255, 0.05);
    }
  }
}
</style>

<template>
  <component :is="icon" :size="size" />
</template>

<script>
import FeatherChevronLeft from './FeatherChevronLeft.vue';
import FeatherChevronRight from './FeatherChevronRight.vue';
import FeatherChevronsLeft from './FeatherChevronsLeft.vue';
import FeatherChevronsRight from './FeatherChevronsRight.vue';
import FeatherCloudOff from './FeatherCloudOff.vue';
import FeatherCloud from './FeatherCloud.vue';
import FeatherCpu from './FeatherCpu.vue';
import FeatherEdit2 from './FeatherEdit2.vue';
import FeatherEdit3 from './FeatherEdit3.vue';
import FeatherEdit from './FeatherEdit.vue';
import FeatherFileText from './FeatherFileText.vue';
import FeatherFile from './FeatherFile.vue';
import FeatherGitBranch from './FeatherGitBranch.vue';
import FeatherHeart from './FeatherHeart.vue';
import FeatherHome from './FeatherHome.vue';
import FeatherList from './FeatherList.vue';
import FeatherMapPin from './FeatherMapPin.vue';
import FeatherPackage from './FeatherPackage.vue';
import FeatherPause from './FeatherPause.vue';
import FeatherPlay from './FeatherPlay.vue';
import FeatherPlusCircle from './FeatherPlusCircle.vue';
import FeatherPlusSquare from './FeatherPlusSquare.vue';
import FeatherPlus from './FeatherPlus.vue';
import FeatherSave from './FeatherSave.vue';
import FeatherSearch from './FeatherSearch.vue';
import FeatherSettings from './FeatherSettings.vue';
import FeatherShare2 from './FeatherShare2.vue';
import FeatherShare from './FeatherShare.vue';
import FeatherStar from './FeatherStar.vue';
import FeatherTerminal from './FeatherTerminal.vue';
import FeatherUser from './FeatherUser.vue';
import FeatherZapOff from './FeatherZapOff.vue';
import FeatherZap from './FeatherZap.vue';
import FeatherZoomIn from './FeatherZoomIn.vue';
import FeatherZoomOut from './FeatherZoomOut.vue';

export default {
  name: 'icon',
  props: {
    icon: {
      type: String,
      required: true,
    },
    size: {
      type: Number,
      default: 24,
    },
  },
  components: {
    FeatherChevronLeft,
    FeatherChevronRight,
    FeatherChevronsLeft,
    FeatherChevronsRight,
    FeatherCloudOff,
    FeatherCloud,
    FeatherCpu,
    FeatherEdit2,
    FeatherEdit3,
    FeatherEdit,
    FeatherFileText,
    FeatherFile,
    FeatherGitBranch,
    FeatherHeart,
    FeatherHome,
    FeatherList,
    FeatherMapPin,
    FeatherPackage,
    FeatherPause,
    FeatherPlay,
    FeatherPlusCircle,
    FeatherPlusSquare,
    FeatherPlus,
    FeatherSave,
    FeatherSearch,
    FeatherSettings,
    FeatherShare2,
    FeatherShare,
    FeatherStar,
    FeatherTerminal,
    FeatherUser,
    FeatherZapOff,
    FeatherZap,
    FeatherZoomIn,
    FeatherZoomOut,
  },
};
</script>

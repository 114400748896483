<template>
  <div :style="computedStyle" v-show="opened" class="modal-wrapper" ref="wrapper">
    <form class="modal-inner" @submit.prevent="$emit('submit')">
      <header class="modal-header">
        <slot name="header">
          <h4>{{ heading }}</h4>
        </slot>
        <!-- todo: add a close button -->
      </header>

      <main class="modal-body">
        <slot></slot>
      </main>

      <footer class="modal-footer">
        <slot name="footer">
          <button @click="close" type="button" class="btn btn-sm btn-light">
            {{ cancelButtonText }}
          </button>
          <button type="submit" class="btn btn-sm btn-primary">{{ acceptButtonText }}</button>
        </slot>
      </footer>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'modal',
  props: {
    name: {
      type: String,
      required: true,
    },
    heading: {
      type: String,
    },
    cancelButtonText: {
      type: String,
      default: 'Cancel',
    },
    acceptButtonText: {
      type: String,
    },
  },
  computed: {
    ...mapGetters({
      isOpened: 'modal/isOpened',
      indexOf: 'modal/indexOf',
    }),
    opened() {
      return this.isOpened(this.name);
    },
    index() {
      return this.indexOf(this.name);
    },
    computedStyle() {
      return {
        'z-index': 2000 + this.index,
      };
    },
  },
  methods: {
    ...mapMutations({
      closeImpl: 'modal/close',
    }),
    close() {
      this.closeImpl(this.name);
    },
  },
};
</script>

<style lang="scss">
.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.4);
}

.modal-inner {
  width: 600px;
  height: auto;

  margin-bottom: 7vh;
  border-radius: 4px;

  background-color: #282c34;
}
</style>

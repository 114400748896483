/**
 * Code of unknown error.
 */
export const ERROR_CODE_UNKNOWN = 9999;

/**
 * Code of error when the Authorization header is missing.
 */
export const ERROR_CODE_AUTHORIZATION_HEADER_MISSING = 4302;

/**
 * Code of error when the user is not logged in or the session has expired.
 */
export const ERROR_CODE_JWT_INVALID = 4303;

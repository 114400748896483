import store from '../../store';

/**
 * A simple modal controller which will be injected into every Vue component as
 * a computed property via the ModalPlugin.
 */
class Modal {
  /**
   * Returns `true` if the specified modal is opened; `false` otherwise.
   *
   * @param {string} name
   */
  isOpened(name) {
    return store.getters['modal/isOpened'](name);
  }

  /**
   * Opens the specified modal.
   *
   * @param {string} name
   */
  open(name) {
    store.commit('modal/open', name);
  }

  /**
   * Closes the specified modal.
   *
   * @param {string} name
   */
  close(name) {
    store.commit('modal/close', name);
  }

  /**
   * Opens a simple alert modal with the specified message.
   *
   * @param {string} msg
   */
  alert(msg) {
    window.alert(msg);
  }
}

const modal = new Modal();

export default modal;

<template>
  <button class="btn icon-btn d-flex align-items-center" :disabled="disabled" @click="click">
    <icon :icon="icon" :size="iconSize" />
    <slot>
      <component :is="textComponent" v-if="text" class="ms-1">{{ text }}</component>
    </slot>
  </button>
</template>

<script>
export default {
  name: 'IconButton',
  props: {
    icon: {
      type: String,
      required: true,
    },
    iconSize: {
      type: Number,
      default: undefined,
    },
    text: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    textComponent() {
      return this.small ? 'small' : 'span';
    },
  },
  methods: {
    click(...args) {
      this.$emit('click', ...args);
    },
  },
};
</script>

<template>
  <panel hide-header hide-footer class="visualizer-panel">
    <algorithm-visualizer />
  </panel>
</template>

<script>
import { mapActions } from 'vuex';
import Panel from './Panel.vue';
import AlgorithmVisualizer from '../visualizers/AlgorithmVisualizer.vue';

export default {
  name: 'visualizer-panel',
  components: {
    Panel,
    AlgorithmVisualizer,
  },
  methods: {
    ...mapActions({
      initializePlayer: 'visualizer/initializePlayer',
    }),
  },
  created() {
    // todo: move this to AlgorithmVisualizer
    this.initializePlayer();
  },
};
</script>
